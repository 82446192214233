<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="演出/影片" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入演出/影片"></a-input>
					</a-form-item>

					<a-form-item label="戏剧类型" class="ui-form__item" name="type">
						<a-select v-model:value="formState.type" placeholder="请选择戏剧类型" allow-clear style="width: 180px;">
							<a-select-option :value="1">影片</a-select-option>
							<a-select-option :value="2">演出</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="类型" class="ui-form__item" name="businessType">
						<a-select v-model:value="formState.businessType" placeholder="请选择类型" allow-clear style="width: 180px;">
							<a-select-option :value="1">评价</a-select-option>
							<a-select-option :value="2">评论</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="审核状态" name="auditStatus" class="ui-form__item">
						<a-select v-model:value="formState.auditStatus" placeholder="请选择审核状态" allow-clear style="width: 180px;">
							<a-select-option :value="0">待审核</a-select-option>
							<a-select-option :value="1">审核通过</a-select-option>
							<a-select-option :value="2">审核拒绝</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="创建时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="primary" v-permission="['comment_check_audit']" :disabled="selectedRowKeys.length === 0" @click="onBatchAudio(1)">批量通过</a-button>
						<a-button style="margin-left: 20px;" v-permission="['comment_check_audit']" type="primary" :disabled="selectedRowKeys.length === 0" @click="onBatchAudio(2)">批量拒绝</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, getCheckboxProps: getCheckboxProps, onSelect: onSelectChange, onSelectAll: onSelectAll }" :scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'type'">
							{{ record.type === 1 ? '影片' : '演出' }}
						</template>
						<template v-if="column.key === 'businessType'">
							{{ record.businessType === 1 ? '评价' : '评论' }}
						</template>
						<template v-if="column.key === 'auditStatus'">
							{{ ['待审核', '审核通过', '审核拒绝'][record.auditStatus] }}
						</template>
						<template v-if="column.key === 'content'">
							<div v-if="record.imageUrl">
								<a-image v-for="item in record.imageUrl.split(',')" :key="item" :src="item" style="width: 80px;margin: 0 4px 4px 0;"></a-image>
							</div>
							<a-tooltip placement="topLeft">
							    <template #title>
							          <span>{{ record.comment }}</span>
							    </template>
								<div ref="content" class="ui-from__content">{{ record.comment }}</div>
							</a-tooltip>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']" v-if="record.auditStatus === 0">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['comment_check_audit']" @click="onAudio(record, 1)">
											<a-menu-item>
												审核通过
											</a-menu-item>
										</div>
										<div v-permission="['comment_check_audit']" @click="onAudio(record, 2)">
											<a-menu-item>
												审核不通过
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<div v-else>--</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getCommentAuditList, commentAudit } from '@/service/modules/comment.js';
	export default {
		components: {
			Icon
		},
		data() {
			return {
				loading: false,
				searchData: {},
				formState: {},
				time: [],
				selectedRowKeys: [],
				columns: [{
					title: '所属影片/演出',
					dataIndex: 'name',
					width: 100
				}, {
					title: '评价时间',
					key: 'createTime',
					width: 120
				}, {
					title: '戏剧类型',
					key: 'type',
					width: 100
				}, {
					title: '评价类型',
					key: 'businessType',
					width: 100
				}, {
					title: '状态',
					key: 'auditStatus',
					width: 100
				}, {
					title: '内容',
					key: 'content',
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 80
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getCommentAuditList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onAudio(item, status) {
				this.$confirm({
					title: '提示',
					content: `确定审核${ status === 1 ? '通过' : '拒绝' }该评价吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await commentAudit({
							id: item.id,
							auditStatus: status
						})
						this.loading = true;
						if (ret.code === 200) {
							this.$message.success('审核成功');
							this.getData();
						}
					}
				})
			},
			onBatchAudio(status) {
				this.$confirm({
					title: '提示',
					content: `确定批量审核${ status === 1 ? '通过' : '拒绝' }吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await commentBatchAudit({
							ids: this.selectedRowKeys,
							auditStatus: status
						})
						this.loading = true;
						if (ret.code === 200) {
							this.selectedRowKeys = [];
							this.$message.success('批量审核成功');
							this.getData();
						}
					}
				})
			},
			getCheckboxProps(record) {
				return {
					disabled: record.auditStatus != 0,
					id: record.id.toString()
				}
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	.ui-from__content {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* 控制行数 */
		-webkit-box-orient: vertical;
	}

	.ui-form__label {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		color: #65bde3;
		cursor: pointer;
	}

	.ui-title {
		margin: 20px 0 10px;
		padding-left: 10px;
		font-size: 16px;
		font-weight: 600;
		position: relative;
	}

	.ui-title::before {
		content: "";
		width: 4px;
		height: 20px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: #1890ff;
		border-radius: 2px;
	}
</style>